/* Page Container */
.wet-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    text-align: center;
    margin-top: -2vw;
  }
  
  /* Heading */
  .wet-container  h1 {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    margin-top: -30px;
  }
  
  /* Dynamic Underline */
  .wet-container h1::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-color: #007bff;
    margin-top: 5px;
    border-radius: 2px;
  }
  /* #555e67 */
  /* Description */
  .wet-container p {
    font-size: 17px;
    color: #ffffff;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 0px;
  }

  .wet-container strong{
    color: #ffffff;
}
  
  /* Articles List - Full Width Boxes */
  .articles-list {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between articles */
  }
  
/* Individual Article Box */
.article-box {
    background: linear-gradient(135deg, #555e67, #0056b3);
    color: white;
    padding: 40px 30px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between; /* Pushes text left & arrow right */
    width: 100%;
    max-width: 1000px; /* Ensures it doesn’t stretch too much */
    margin: 0 auto; /* Centering */
    text-align: left;
    position: relative;
  }
  
  /* Arrow at the End */
  .article-box::after {
    content: "➜"; /* Unicode right arrow */
    font-size: 28px;
    color: white;
    transition: transform 0.3s ease;
  }
  
  /* Hover Effect */
  .article-box:hover {
    background: linear-gradient(135deg, #4b555f, #003d82);
    transform: scale(1.02);
  }
  
  /* Move Arrow Slightly on Hover */
  .article-box:hover::after {
    transform: translateX(5px); /* Moves arrow inward */
  }
  