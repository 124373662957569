/* ===== Page Layout ===== */
.legal-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 0px;
    /* backdrop-filter: blur(10px); */
    border-radius: 0px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    text-align: center;
    margin-top: -100px;
}

/* ===== Headings ===== */
.legal-container h1 {
    font-size: 36px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
}

.legal-container h1::after {
    content: "";
    display: block;
    width: 100%; /* Matches text width */
    height: 4px;
    background-color: #007bff;
    margin-top: 5px;
    border-radius: 2px;
}

.legal-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 30px;
}

.legal-container h3 {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 25px;
}

/* ===== Paragraphs ===== */
.legal-container p {
    font-size: 16px;
    line-height: 1.8;
    color: #ffffff;
    margin-bottom: 15px;
    text-align: justify;
}

/* ===== Highlighted Sections ===== */
.legal-highlight {
    background-color: rgba(0, 91, 187, 0.1);
    padding: 10px 15px;
    border-left: 4px solid #007bff;
    border-radius: 5px;
    margin: 15px 0;
}

/* ===== Important Notes & Contact Info ===== */
.legal-note {
    font-size: 15px;
    font-weight: bold;
    color: #0056b3;
    margin-top: 20px;
    text-align: center;
}

.legal-contact {
    font-size: 16px;
    font-weight: bold;
    color: #0056b3;
    text-align: center;
    margin-top: 20px;
}

/* ===== Responsive Design ===== */
@media (max-width: 768px) {
    .legal-container {
        max-width: 90%;
        padding: 20px;
    }

    .legal-container h1 {
        font-size: 30px;
    }

    .legal-container h2 {
        font-size: 22px;
    }

    .legal-container h3 {
        font-size: 18px;
    }

    .legal-container p {
        font-size: 15px;
    }
}
