/* Scroll Section */
.scroll-section {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 20px 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

/* Wrapper to Center the Scrolling Content */
.scroll-wrapper {
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  position: relative;
}

/* Horizontal Scroll Animation */
@keyframes scrollAnimation {
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
}

/* Make Animation Responsive */
.scroll-container {
  display: flex;
  gap: 20px;
  width: 200%;
  min-width: 100%;
  animation: scrollAnimation 30s linear infinite;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: nowrap; /* Prevents breaking into multiple lines */
}

/* Scrollable Items */
.scroll-item {
  min-width: 270px;
  max-width: 100%;
  height: auto;
  min-height: 400px; /* Ensures space for text */
  background-color: rgb(39, 46, 122);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px;
  overflow: hidden; /* Prevents text overflow */
}

/* Hover Effect */
.scroll-item:hover {
  transform: scale(1.05);
}

/* Image Styling */
.scroll-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

/* Ensuring Text Fits Inside the Box */
.scroll-item h3 {
  font-size: min(1.4rem, 3vw);
  color: #ffffff;
  text-align: center;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.4;
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

/* Arrow - Keeps it at the End of the Last Line */
.scroll-item h3 .arrow {
  display: inline-block;
  white-space: nowrap;
  font-size: 1.3rem;
  margin-left: 8px;
  transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out;
}

/* Hover Effect: Arrow Gets Bigger and Moves Left */
.scroll-item:hover h3 .arrow {
  font-size: 1.4rem;
  transform: translateX(5px);
}

/* Responsive Design */
@media (max-width: 900px) {
  .scroll-wrapper {
      width: 90%;
  }
  
  .scroll-item {
      min-width: 180px;
      min-height: 250px;
  }

  .scroll-item img {
      height: 160px;
  }
}

@media (max-width: 600px) {
  .scroll-wrapper {
      width: 95%;
  }

  .scroll-item {
      min-width: 160px;
      min-height: 220px;
  }

  .scroll-item img {
      height: 140px;
  }

  .scroll-container {
      animation: scrollAnimation 18s linear infinite;
      animation-delay: 3s;
  }
}
