/* ===== Page Layout ===== */
.page-container {
    max-width: 1200px;
    margin: 10px auto;
    padding: 20px;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: min(0vw, -3vw);
  }
  
/* ===== Headings ===== */
.page-container h1 {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
    display: block; /* Ensures it takes full width */
    /* width: max-content; */ /* Only takes up as much space as its text */
    margin: 0 auto 20px auto; /* Center horizontally */
    padding-bottom: 10px; /* Space between text and underline */
  }
  
  /* Dynamic Underline for h1 */
.page-container h1::after {
    content: "";
    display: block;
    width: 100%; /* Matches text width */
    height: 4px;
    background-color: #007bff;
    margin-top: 5px;
    border-radius: 2px;
  }
  
  /* ===== Subheadings (h2, h3) ===== */
  .page-container  h2 {
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 30px;
  }
  
  .page-container h3 {
    font-size: 22px;
    font-weight: bold;
    color: white;
    margin-top: 20px;
  }
  
  /* ===== Paragraphs ===== */
  .page-container p {
    font-size: 16px;
    line-height: 1.6;
    color: white;
    text-align: justify;
    margin-bottom: 20px;
  }
  
  /* ===== Links (Navbar Pages) ===== */
  a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  /* ===== Buttons ===== */
  .page-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: linear-gradient(135deg, #007bff, #0056b3);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    margin-top: 20px;
}
  
  .page-button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: scale(1.05);
  }
  
  /* ===== Page Sections (For Better Readability) ===== */
  .page-section {
    margin-top: 40px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  
  /* ===== Responsive Design ===== */
  @media (max-width: 768px) {
    .page-container {
      max-width: 90%;
      padding: 15px;
    }
  
    .page-container h1 {
      font-size: 28px;
    }
  
    .page-container h2 {
      font-size: 24px;
    }
  
    .page-container p {
      font-size: 15px;
    }
  
    .page-button {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
  