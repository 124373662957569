/* Reset Styles */
html, body {
  margin: 0;
  padding: 0;
}

/* Navbar Styling */
.navbar {
  font-family: 'Poppins', sans-serif;
  background-color: #121212;
  padding: 0 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.navbar-logo1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.navbar-logo1 img {
  width: 85px;  /* Set a fixed width */
  height: auto;  /* Maintain aspect ratio */
  object-fit: contain;  /* Ensure the image fits properly */
  margin-left: -25px;
  margin-top: 5px;
  transition: opacity .8s ease-in-out; /* Smooth transition */
  transition: opacity 0.8s ease-in-out;
}

.navbar-list {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* Navbar Buttons */
.navbar-button {
  font-size: 1.2vw;
  font-weight: 600;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 10px;
  transition: color 0.3s ease-in-out, transform 0.2s ease;
}

.navbar-button:hover {
  color: rgb(77, 89, 213);
  transform: scale(1.05);
}

/* Dropdown */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  background: #181818;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: none;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.dropdown.active {
  display: flex;
}

.dropdown-section {
  text-align: center;
  min-width: 250px;
}

.dropdown-title {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.dropdown-item {
  padding: 8px 12px;
  font-size: 14px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.2s ease;
}

.dropdown-item:hover {
  color: #a259ff;
}

/* Burger Menu Styles */
.navbar-toggle {
  display: none; /* Hidden by default */
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

.bar.active {
  background-color: rgb(77, 89, 213); /* Change color when active */
}

/* Show burger menu on smaller screens */
@media (max-width: 768px) {
  .navbar-list {
    display: none; /* Hide the list by default */
    flex-direction: column;
    position: absolute;
    top: 70px; /* Below the navbar */
    left: 0;
    right: 0;
    background-color: #121212;
    padding: 20px;
    z-index: 9999;
  }

  .navbar-list.active {
    display: flex; /* Show the list when active */
  }

  .navbar-toggle {
    display: flex; /* Show burger icon on mobile */
  }
}

/* Mobile Menu */
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background: #121212; /* Match navbar background */
  box-shadow: -2px 0 5px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 1000;
  font-family: 'Poppins', sans-serif; /* Match navbar font */
}

.close-button {
  font-size: 24px;
  align-self: flex-end;
  border: none;
  background: none;
  color: white; /* Match navbar text color */
  cursor: pointer;
}

.mobile-menu-list {
  list-style: none;
  padding: 0;
}

.mobile-menu-item {
  margin: 10px 0;
}

.mobile-menu-button {
  background: none;
  border: none;
  font-size: 1.2vw; /* Match navbar button size */
  font-weight: 600; /* Match navbar font weight */
  color: white; /* Match navbar text color */
  cursor: pointer;
  padding: 10px 10px;
  transition: color 0.3s ease-in-out, transform 0.2s ease;
}

.mobile-menu-button:hover {
  color: rgb(77, 89, 213); /* Match navbar hover color */
  transform: scale(1.05);
}

.mobile-dropdown {
  padding-left: 15px;
}

.navbar-toggle {
  display: block;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    font-size: 16px; /* Ensure readability on smaller screens */
  }
}

@media (min-width: 769px) {
  .mobile-menu, .navbar-toggle {
    display: none;
  }
}
