/* ===== Page Layout ===== */
.form-page-container {
    max-width: 1200px;
    margin: clamp(10px, 3vw, 40px) auto;  /* Dynamically adjust spacing */
    padding: clamp(10px, 2vw, 30px); /* Less padding at smaller screen sizes */
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: clamp(10px, 2vw, 30px); /* Reduce gap between elements */
    margin-top: min(-7vw, 4vw); /* Prevents margin from getting too large */
}

/* ===== Headings ===== */
.form-page-container h1 {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0px;
    padding-bottom: 1px;
}

/* Dynamic Underline for h1 */
.form-page-container h1::after {
    content: "";
    display: block;
    width: 100%; /* Matches text width */
    height: 4px;
    background-color: #007bff;
    margin-top: 5px;
    border-radius: 2px;
}

/* ===== Buttons Section ===== */
.form-page-button-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Ensures responsiveness */
    gap: 20px; /* Space between buttons */
    margin-top: 20px;
}

/* Button Styles */
.form-page-button-group button {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: linear-gradient(135deg, #007bff, #0056b3);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    min-width: 180px; /* Makes sure buttons are evenly sized */
}

.form-page-button-group button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: scale(1.05);
}

/* ===== Responsive Design ===== */
@media (max-width: 768px) {
    .form-page-container {
        max-width: 90%;
        padding: 15px;
    }

    .form-page-container h1 {
        font-size: 28px;
    }

    .form-page-button-group {
        flex-direction: column; /* Stack buttons on smaller screens */
    }

    .form-page-button-group button {
        width: 100%;
    }
}

@media (min-width: 1400px) {
    .form-page-container {
        margin-top: -170px; /* Reduces space on very large screens */
        max-width: 1200px; /* Limits the width on extra-wide screens */
    }
}
