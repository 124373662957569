/* General Form Styling */
.form-container {
  max-width: 600px;
  margin: 40px auto;
  /* background-color: #f9f9f9; */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  text-align: center;
  margin-top: -100px;
}

/* Form Heading */
.form-container h1 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}

/* Form Grid */
.form-grid {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Labels */
.form-container label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
}

/* Input & Select Fields */
.form-container input[type="text"],
.form-container input[type="email"],
.form-container input[type="tel"],
.form-container input[type="file"],
.form-container select,
.form-container textarea {
  margin-top: 10px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Input Focus Effect */
.form-container input:focus,
.form-container textarea:focus,
.form-container select:focus {
  border-color: #007bff;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.3);
}

/* Textarea Styling */
.form-container textarea {
  resize: vertical;
  min-height: 120px;
}

/* Submit Button */
.form-container button {
  padding: 12px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.form-container button:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-container {
    max-width: 90%;
    padding: 15px;
  }
  
  .form-container label {
    font-size: 14px;
  }

  .form-container input, 
  .form-container textarea, 
  .form-container select {
    font-size: 13px;
    padding: 10px;
  }
  
  .form-container button {
    font-size: 14px;
    padding: 10px;
  }
}
