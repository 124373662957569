/* ===== Leaders Page Layout ===== */
.leaders-container {
    max-width: 1200px;
    margin: 10px auto;
    padding: 20px;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: min(0vw, -20vh);
}

/* ===== Heading Styling (Same as Other Pages) ===== */
.leaders-container h1 {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
    padding-bottom: 10px;
    position: relative;
}

/* Underline Effect for h1 */
.leaders-container h1::after {
    content: "";
    display: block;
    width: 40%;
    height: 4px;
    background-color: #007bff;
    margin: 5px auto 0 auto;
    border-radius: 2px;
}

/* ===== Leaders Grid (2 per Row) ===== */
.leaders-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 30px;
    margin-top: 30px;
}

/* Leader Card */
.leader-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out;
}

.leader-card:hover {
    transform: translateY(-5px);
}

/* Leader Image */
.leader-image {
    width: 100%;
    max-width: 200px; /* Ensures large images */
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Leader Name */
.leader-name {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-top: 15px;
}

/* ===== Leader Role (h4) ===== */
.leader-role {
    font-size: 20px;
    font-weight: 600;
    color: #f0f0f0;
    margin-top: 5px; /* Reduce space between name and role */
    margin-bottom: 10px; /* Reduce space before description */
}

/* ===== Leader Description (p) ===== */
.leader-description {
    font-size: 16px;
    color: white;
    margin-top: 0px; /* Ensures minimal space between role and description */
    line-height: 1.4; /* Keeps text readable without too much spacing */
    text-align: center;
}

/* ===== Responsive Design (1 Column on Mobile) ===== */
@media (max-width: 768px) {
    .leaders-grid {
        grid-template-columns: 1fr; /* Stacks leaders in one column */
    }

    .leader-card {
        padding: 15px;
    }

    .leader-name {
        font-size: 22px;
    }

    .leader-description {
        font-size: 14px;
    }
}
