/* Layout Container */
.layout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: transparent;
  min-height: 100vh;
}

.layout-container h1 {
  color: white;
  margin-top: 15vh;
  font-size: 2rem; /* Changed to rem for better scalability */
}

.layout-container h1::after {
  content: "";
  display: block;
  width: 0px;
  height: 3px;
  background-color: #007bff;
  margin-top: 5px;
  border-radius: 2px;
}

/* Navbar Container (Align Logo & Navbar) */
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background-color: #121212;
  padding: 0 2rem; /* Changed to rem for scalability */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.navbar-title {
  width: 100%;
  text-align: center;
  margin-top: 4vw; /* Space below the navbar */
  padding: 10px;
}

.navbar-title h1 {
  font-size: 1.125rem; /* Adjusted font size */
  color: white;
  font-weight: bold;
  margin: 0;
}

/* Left Corner Logo */
.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-logo img {
  height: 5.625rem; /* Adjusted to rem for scalability */
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

/* Center Logo on Page */
.center-logo-container {
  display: flex;
  justify-content: center;
  margin-top: 5rem; /* Adjusted for better spacing */
}

.center-logo-container img {
  height: 7.5rem; /* Adjusted to rem for scalability */
  max-width: 90%;
  object-fit: contain;
}

/* Page Content */
.page-content {
  margin-top: 3vw;
  width: 100%;
  max-width: 100%;
  flex: 1;
  overflow: visible;
  position: relative;
}

/* Footer Container */
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
}

/* Footer - Left Side */
.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  padding-left: 1.25rem;
  margin-top: 5vh;
}

.footer-content {
  display: flex;
  gap: 4.375rem; /* Adjusted gap for better spacing */
}

/* Footer Sections */
.footer-section {
  min-width: 9.375rem; /* Adjusted width */
}

.footer-section h3 {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.625rem; /* Adjusted margin for consistency */
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.footer-section ul li {
  margin-bottom: 0.9375rem;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section ul li a:hover {
  color: #00aaff;
}

/* Footer - Copyright */
.footer-copyright {
  margin-top: 2.5rem;
  font-size: 0.875rem; /* Adjusted to rem */
  color: #ffffff;
  text-align: center;
  width: 100%;
  margin-left: -2rem; /* Adjusted for better centering */
}

/* Footer - Right Side (Media) */
.footer-media {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding-right: 6.25rem; /* Adjusted padding */
}

.footer-media img, 
.footer-media video {
  width: 100%;
  max-width: 43.75rem; /* Adjusted to ensure proper scaling */
  height: auto;
  border-radius: 1.25rem; /* Added for aesthetics */
  margin-right: -3.125rem; /* Adjusted margin */
  -webkit-mask-image: radial-gradient(circle, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%);
  mask-image: radial-gradient(circle, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 100%);
  box-shadow: inset 0px 0px 5rem 1.25rem rgba(0,0,0,0.8); /* Subtle shadow effect */
}

/* Google Translate Dropdown */
#google_translate_element {
  position: absolute;
  left: 100%;
  transform: translateX(50%);
  z-index: 9999;
}

.goog-te-gadget select {
  background-color: #ffffff;
  color: white !important;
  border: 1px solid white;
  padding: 5px 10px;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  cursor: pointer;
}

.goog-te-combo {
  background-color: #ffffff !important;
  color: white !important;
  border: 1px solid white;
  padding: 5px 8px;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-left, .footer-content {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }

  .footer-content {
    flex-direction: column;
    gap: 1.25rem;
  }

  .footer-media {
    max-width: 80%;
    padding-right: 0;
  }
}

/* Footer */
.footer {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  border-top: 2px solid #ddd;
  font-size: 1rem;
  color: #000;
  background-color: transparent;
}

/* Responsive Navbar Adjustments */
@media (max-width: 900px) {
  .navbar-container {
    padding: 0 1.25rem;
  }

  body {
    padding-top: 4.6875rem;
  }
}

/* Small Screens */
@media (max-width: 600px) {
  .navbar-container {
    flex-direction: column;
    height: auto;
    padding: 0.625rem 1.25rem;
  }
}
