/* ===== Page Layout ===== */
.locations-container {
    max-width: 1200px;
    margin: clamp(10px, 3vw, 40px) auto;  /* Dynamically adjust spacing */
    padding: clamp(10px, 2vw, 30px);
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: min(0vw, -3vw);

}

/* ===== Headings & Description ===== */
.locations-container h1 {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 10px;
    width: max-content;
    margin: 0 auto;
    padding-bottom: 8px;
}

.locations-container h1::after {
    content: "";
    display: block;
    width: 100%; /* Matches text width */
    height: 4px;
    background-color: #007bff;
    margin-top: 5px;
    border-radius: 2px;
}

.locations-container p {
    font-size: 18px;
    color: white;
    margin-bottom: 15px;
    text-align: center;
}

/* ===== Main Content Layout (Locations List on Left, Map on Right) ===== */
.locations-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
    margin-top: 20px;
}

/* ===== Left Side (Locations List) ===== */
.locations-list {
    flex: 1;
    max-width: 280px;
    text-align: left;
    padding: 10px;
    /* background: rgba(255, 255, 255, 0.1);  */
    backdrop-filter: blur(8px);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* Smaller, compact list */
.location-item {
    background: #0056b3;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    text-align: left;
    width: 100%;
    display: block; /* Ensure they stack */
    margin-bottom: 8px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    border: none;
}

/* Hover effect */
.location-item:hover {
    background: #004091;
    transform: translateX(4px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
}

.location-item.active {
    background: #004091;
    color: #fff;
    border: 2px solid #007bff;
    transform: scale(1.05);
  }

/* ===== Right Side (Map) ===== */
.map-container {
    flex: 2;
    height: 500px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.dark-mode-toggle {
    margin: 5px;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 6px;
    background: transparent;
    color: white;
    transition: 0.3s ease-in-out;
    width: 10%;
    margin-left: auto;
    margin-top: .5vw;
  }
  
.dark-mode-toggle:hover {
    background: #0056b3;
}

/* ===== Responsive Design (Stacked on Smaller Screens) ===== */
@media (max-width: 900px) {
    .locations-content {
        flex-direction: column;
        align-items: center;
    }

    .locations-list {
        max-width: 100%;
        text-align: center;
        padding: 10px;
    }

    .location-item {
        text-align: center;
        width: auto;
        display: inline-block;
    }

    .map-container {
        width: 100%;
        height: 400px;
    }
}
